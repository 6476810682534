import { registerApplication, start } from 'single-spa';

import * as serviceWorker from './serviceWorker';
import { TOKEN_NAME } from './consts';
import { EApp } from './enums';
import i18n from './i18n';

const accessToken: string = localStorage.getItem(TOKEN_NAME) || '';

i18n.init.then(() => {
    /**
     * Register Login app.
     */
    registerApplication(
        EApp.LOGIN,
        () => import('apps/login'),
        () => !accessToken,
    );
    /**
     * Register Admin app.
     */
    registerApplication(
        EApp.MAIN,
        () => import('apps/main'),
        () => Boolean(accessToken),
    );

    start();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
